html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  width: 100%;
  box-sizing: border-box;
}
.offscreen {
  position: absolute;
  left: -9999px;
}
li {
  list-style-type: none;
}
#sidebar a {
  text-decoration: none;
  color: rgb(139, 0, 139);
  text-shadow: rgba(0, 0, 0, 0.638) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
#sidebar a.active {
  color: rgb(139, 0, 139);
  font-weight: bold;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background-color: rgb(20,20,20); */
  background-color: rgb(250, 148, 148);
  /* background: rgb(0,0,0); */
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
@media (orientation: portrait) {
  /* Styles for portrait orientation */
  #root {
    height: 100%;
    /* ... other styles ... */
  }
}
@media (orientation: landscape) {
  /* Styles for portrait orientation */
  #root {
    height: 100vh;
    /* ... other styles ... */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

tr.same-col-widths td {
  width: 50%;
}
#rootcontainer {
  height: 100%;
  width: 100%;
}
#sidebar {
  display: block;
  z-index: 5;
}

#navbaritems {
  display: flex;
  justify-content: center;
  gap: 2rem;

  padding: 0.5rem 0.1rem 0;
}
#detail {
  display: block;

  width: 100%;
  height: 100%;
}
#task-description {
  color: rgb(15, 15, 15);
  background-color: rgb(246, 117, 117);
  margin-top: 1.5rem;
  font-size: 1.3em;
  padding: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
#card-container {
  display: flex;
  flex-direction: row;
  /* background-color: blueviolet; */
  flex: 1;
  flex-wrap: wrap;
  min-height: 10rem;
  /* max-height: calc(max-content + 5rem) ; */
  width: 40vw;
}
#card-container * {
  flex: 1;
  flex-wrap: wrap;
}
@media only screen and (max-width: 700px) {
  #card-container {
    width: 100vw;
    /* ... other styles ... */
  }
}
@media only screen and (min-height: 500px) {
  #card-container {
    max-height: 20rem;
  }
}
#card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  border-radius: 0.2rem;
  background-color: rgb(254, 196, 196);
  padding: 0.5rem;
  margin: 0.5rem;
  flex: 1;
}

#card * > {
  flex-wrap: wrap;
}
#button-container {
}
Button {
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 5px;
  margin: 0.5rem;
}
.item {
  scale: 0.9;
}
#score {
  color: rgb(255, 0, 170);
  font-size: 1.1em;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  flex: 1;
}
#name {
  font-size: 1.2em;
  text-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  flex: 1;
}
#starbucket {
  width: 80%;
  transform: rotate(180deg);
  align-content: bottom;
  min-height: 1.5rem;
  flex: 1;
}
.star {
  text-shadow: 0 -0.5rem 1rem #f4f3efe0, 0 0.5rem 1rem #f8f6f6df;
}
/*************************************admin-panel**********************************/
#admin-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 3.5rem;
  z-index: 4;
}
#admin-panel * > {
  flex: 1;
}
.menu * > {
  flex: 1;
}

#description-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

#editdelete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
}
#tracker-name {
  margin-top: -2rem;
  margin-bottom: 0.5rem;
  color: blue;
  font-size: 1.2em;
}

.input {
  display: flex;
  margin-bottom: 1rem;
}
#summary {
  height: 100%;
  width: 100%;
  background-color: rgb(30, 30, 30);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: absolute;
  padding: 2.5rem;
  margin: 0;
}

#cards {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 50rem;
  padding: 1rem;
  justify-content: center;
  overflow: auto;
  background-color: rgb(12, 12, 8);
  position: relative;
}

.card {
  min-width: 15rem;
  min-height: 15rem;

  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.3rem;
  border: 1px;
}
@media only screen and (max-width: 700px) {
  #summary {
    padding: 1.5rem;
  }
  #cards {
    max-width: 100%;
  }
  .card {
    min-width: 10rem;
    min-height: 10rem;
  }

  .card::before {
    opacity: 0;
    background: radial-gradient(
      20rem circle at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    pointer-events: none;
    z-index: 3;
  }
  .card::after {
    opacity: 0;
    background: radial-gradient(
      18rem circle at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.4),
      transparent 40%
    );
    z-index: 1;
  }
  #navbaritems {
    font-size: 0.8em;
  }
}

.card::before,
.card::after {
  left: 0px;
  top: 0px;
  position: absolute;
  content: "";
  border-radius: inherit;
  height: 100%;
  width: 100%;
  transition: opacity 500ms;
}
.card::before {
  opacity: 0;
  background: radial-gradient(
    35rem circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.06),
    transparent 40%
  );
  pointer-events: none;
  z-index: 3;
}
.card::after {
  opacity: 0;
  background: radial-gradient(
    27rem circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.4),
    transparent 40%
  );
  z-index: 1;
}

.card:hover::before {
  opacity: 1;
}
#cards:hover > .card::after {
  opacity: 1;
}
.card > .card-body {
  background-color: rgb(23, 25, 24); /* rgb(42, 42, 42);*/

  border-radius: inherit;
  position: absolute;
  z-index: 2;
  padding: 2rem;
  inset: 1px;
  flex-grow: 1;
  color: aliceblue;
}
.hidden {
  display: none;
}
.close {
  position: absolute;
  /*right: 50%;
  top: 50%;
  transform: translate(50%, -50%); */
  right: 0;
  top: 1rem;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close {
  animation-name: periodicfading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
}

@keyframes periodicfading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.close:hover {
  animation-name: hover-animation;
  animation-duration: 0.5s;
  animation-iteration-count: initial;
  animation-direction: initial;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes hover-animation {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.close,
.close:before,
.close:after {
  z-index: 5;
  cursor: pointer;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #e6e3e3;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

/*.card::after {  
  background: radial-gradient(
    600px circle at var(--mouse-x) var(--mouse-y), 
    rgba(255, 255, 255, 0.4),
    transparent 40%
  );
  z-index: 1;
}

.card::before,
.card::after {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 500ms;
  width: 100%;
} */
/**********************************************CREATEUSER***********************************/
#username-container,
#password-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#user-exists {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgb(254, 98, 98);
  margin: 0.5rem;
}
#isregistersuccess {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

#addnamessuccess {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#spinner-container {
  display: flex;
  justify-items: center;
  margin: 2rem;
}
#spinner {
}
#isaddidentifiersuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

Input {
  margin-top: 2rem;
}

/********************************************LOGINFORM********************************/

#loginform {
  margin: 2rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgb(249, 155, 155);
}

#default-container {
  margin: 1rem;
}
#default {
  margin: 1rem;
}
.default {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  padding: 1rem;
}
#instruction {
  margin: 1rem;
}
#linktoregister {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
#welcome {
}
/******************************bar chart *********************************/
